import { FormErrorContainer, FormErrorIcon, FormErrorText } from '@components/Form/Error/FormError.styles';
import useTranslation from 'next-translate/useTranslation';
import { TFormErrorProps } from './FormElement.types';

const FormError = ({ text, ...props }: TFormErrorProps): JSX.Element => {
  const I18N_COMMON_CONTEXT = 'common';
  const { t } = useTranslation(I18N_COMMON_CONTEXT);
  return (
    <FormErrorContainer {...props}>
      <FormErrorIcon />
      <FormErrorText>{t(text || '')}</FormErrorText>
    </FormErrorContainer>
  );
};

export default FormError;
