import { SVGProps } from 'react';

export default function Exclamation(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.001 2H2.999C2.73405 2 2.47995 2.10525 2.2926 2.2926C2.10525 2.47995 2 2.73405 2 2.999V13.001C2 13.266 2.10525 13.5201 2.2926 13.7074C2.47995 13.8947 2.73405 14 2.999 14H13.001C13.1322 14 13.2621 13.9742 13.3833 13.924C13.5045 13.8738 13.6146 13.8002 13.7074 13.7074C13.8002 13.6146 13.8738 13.5045 13.924 13.3833C13.9742 13.2621 14 13.1322 14 13.001V2.999C14 2.86781 13.9742 2.7379 13.924 2.6167C13.8738 2.49549 13.8002 2.38537 13.7074 2.2926C13.6146 2.19983 13.5045 2.12625 13.3833 2.07604C13.2621 2.02584 13.1322 2 13.001 2ZM7.4375 4H8.5625V9H7.4375V4ZM8 12C7.85166 12 7.70666 11.956 7.58332 11.8736C7.45999 11.7912 7.36386 11.6741 7.30709 11.537C7.25032 11.4 7.23547 11.2492 7.26441 11.1037C7.29335 10.9582 7.36478 10.8246 7.46967 10.7197C7.57456 10.6148 7.7082 10.5434 7.85368 10.5144C7.99917 10.4855 8.14997 10.5003 8.28701 10.5571C8.42406 10.6139 8.54119 10.71 8.6236 10.8333C8.70601 10.9567 8.75 11.1017 8.75 11.25C8.75 11.4489 8.67098 11.6397 8.53033 11.7803C8.38968 11.921 8.19891 12 8 12Z'
        fill='#EA5358'
      />
    </svg>
  );
}
