import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Subtitle2 } from '@components/Texts/Texts';
import ErrorIcon from '@assets/exclamation';

export const FormErrorContainer = styled.div`
  border-radius: 4px;
  ${tw`h-6 flex flex-row px-2 space-s-1 items-center bg-brand-error-300`}
`;

export const FormErrorText = styled(Subtitle2)`
  ${tw`text-light-high`}
`;

export const FormErrorIcon = styled(ErrorIcon)`
  ${tw`w-4 h-4`}
`;
