import { SVGProps } from 'react';

export default function FacebookCircle(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2624:1966)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.5 9.05304C18.5 4.05318 14.4706 0 9.5 0C4.52943 0 0.5 4.05318 0.5 9.05304C0.5 13.5717 3.79117 17.317 8.09375 17.9961V11.6699H5.80859V9.05304H8.09375V7.05855C8.09375 4.78963 9.43739 3.53635 11.4932 3.53635C12.4779 3.53635 13.5078 3.71316 13.5078 3.71316V5.94106H12.3729C11.2549 5.94106 10.9062 6.63891 10.9062 7.35484V9.05304H13.4023L13.0033 11.6699H10.9062V17.9961C15.2088 17.317 18.5 13.5717 18.5 9.05304Z'
          fill='#1877F2'
        />
      </g>
      <defs>
        <clipPath id='clip0_2624:1966'>
          <rect width='18' height='18' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}
