import { useYela } from '@hooks/useYela';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import ReactFacebookLogin from 'react-facebook-login';
import { CONTINUE_WITH_FACEBOOK, I18N_COMMON_CONTEXT } from './FacebookConnect.constants';
import { FacebookCircleIcon } from './FacebookConnect.styles';
import { FacebookConnectProps } from './FacebookConnect.types';

const FacebookConnect = ({ onClick, onLogin, dynamicURIParams, callerPage, disabled }: FacebookConnectProps): JSX.Element => {
  const { t } = useTranslation(I18N_COMMON_CONTEXT);
  const { language } = useYela();
  const [isComponentLoaded, setIsComponentLoaded] = useState<boolean>(false);
  const additionalStateParams = { callerPage, language };

  useEffect(() => {
    const accessToken = localStorage.getItem('fb_token');
    if (accessToken) {
      localStorage.removeItem('fb_token');
      onLogin(accessToken);
    }
    setIsComponentLoaded(true);
  }, []);

  const isInAppBrowser = () => {
    const windowElem: any = window;
    const ua = navigator.userAgent || navigator.vendor || windowElem.opera;
    return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1 || ua.indexOf('Instagram') > -1;
  };

  return (
    <>
      {isComponentLoaded && (
        <ReactFacebookLogin
          onClick={onClick}
          isDisabled={!process.env.NEXT_PUBLIC_FACEBOOK_CONNECT_APP_ID || disabled}
          appId={process.env.NEXT_PUBLIC_FACEBOOK_CONNECT_APP_ID}
          autoLoad={false}
          fields={'name,email'}
          scope={'email,public_profile'}
          returnScopes={true}
          callback={(r: any) => {
            const accessToken = r?.accessToken;
            const email = r?.email;
            if (accessToken) {
              email ? onLogin(accessToken, email) : onLogin(accessToken);
            }
          }}
          icon={<FacebookCircleIcon />}
          textButton={t(CONTINUE_WITH_FACEBOOK)}
          authType='rerequest'
          size='small'
          disableMobileRedirect={!isInAppBrowser()}
          redirectUri={process.env.NEXT_PUBLIC_SITE_URL + '/social/facebook/auth'}
          responseType='token'
          state={JSON.stringify(
            dynamicURIParams && dynamicURIParams.creator != '' ? { ...dynamicURIParams, ...additionalStateParams } : additionalStateParams,
          )}
          buttonStyle={{
            width: '100%',
            borderRadius: '203px',
            backgroundColor: '#FFFFFF',
            color: 'rgba(37, 37, 38, 1)',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '24px',
            border: 'none',
            outline: 'none',
          }}
        />
      )}
    </>
  );
};

export default FacebookConnect;
