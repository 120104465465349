import API from '@api/api-base';
import Endpoints from '@constants/endpoints';
import { TMessageType } from './Booking';
import { BaseResponse } from './model/base-response';

export interface CelebrityResponse {
  slug: string;
  photo: string;
  video: string;
  name: string;
  surname: string;
  fullname: string;
  available: boolean;
  goingFast: boolean;
  description: string;
  audioPricing: Pricing;
  videoPricing: Pricing;
  audioLimiting: Limiting;
  videoLimiting: Limiting;
}

export interface Pricing {
  enabled: boolean;
  price: number;
  currencyCode: string;
  currencySymbol: string;
}

export interface Limiting {
  enabled: boolean;
  soldOut: boolean;
  totalSlots: number;
  remainingSlots: number;
  resetsInSeconds: number;
}

interface GetCelebrityInfoRequest {
  slug: string;
}

interface getCelebrityPromoPricePayload {
  promotion: string | null;
  messageType: TMessageType;
}

interface getCelebrityPromoPriceResponse {
  amount: number;
  promotionAmount: number;
  currencyCode: string;
  currencySymbol: string;
}

interface createCelebrityNotificationPayload {
  email?: string;
  messageTypes: TMessageType[];
}

interface createCelebrityNotificationResponse {
  email: string;
}

export const GetCelebrityInfo = async (info: GetCelebrityInfoRequest, locale?: string): Promise<BaseResponse<CelebrityResponse>> => {
  const result = await API.get<CelebrityResponse>(Endpoints.CELEBRITY_INFO(info.slug), locale);
  return result;
};

export const getCelebrityPromoPrice = async (
  slug: string,
  payload: getCelebrityPromoPricePayload,
): Promise<BaseResponse<getCelebrityPromoPriceResponse>> => {
  const result = await API.post<getCelebrityPromoPricePayload, getCelebrityPromoPriceResponse>(Endpoints.PROMO(slug), payload);
  return result;
};

export const createCelebrityNotification = async (
  slug: string,
  payload: createCelebrityNotificationPayload,
): Promise<BaseResponse<createCelebrityNotificationResponse>> => {
  const result = await API.post<createCelebrityNotificationPayload, createCelebrityNotificationResponse>(
    Endpoints.NOTIFY_ME(slug),
    payload,
  );
  return result;
};
